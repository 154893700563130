import { initMain } from "./vue/controllers/main";
import { header } from "./vue/controllers/header";
import { TextFill, tfmixin } from "./vue/components/text-fill/TextFill.vue";
import { CarouselPlugin, TabsPlugin } from "bootstrap-vue";
import { homeMixin } from "./vue/mixins/home";
import { ripeTabsMixin } from "./vue/mixins/ripe-tabs";
import TwentyTwenty from "./vue/components/twenty-twenty/TwentyTwenty.vue";
import lazyload from "./features/lazy-load";
import proportionalScroll from "./features/proportional-scroll";
import HScroll from "./vue/components/h-scroll/HScroll.vue";
import MyNews from "./vue/components/my-news/MyNews.vue";
import DolarSpotGraph from "./vue/components/dolar-spot-graph/DolarSpotGraph.vue";
import MiniQuoteGraph from "./vue/components/mini-quote-graph/MiniQuoteGraph.vue";
import QuoteGraph from "./vue/components/quote-graph/QuoteGraph.vue";
import InsideHome from "./vue/components/inside-home/InsideHome.vue";
import Live70Years from "./vue/components/live-70-years/Live70Years.vue";
import Trends from "./vue/components/trends/Trends.vue";
import DayNews from "./vue/components/day-news/DayNews.vue";
import LrPlusBenefits from "./vue/components/lr-plus-benefits/LrPlusBenefits.vue";
import HomeElections2023 from "./vue/components/home-elections-2023/HomeElections2023.vue";
import VideoElections2023 from "./vue/components/video-elections-2023/VideoElections2023.vue";
import VueTinySlider from "vue-tiny-slider";

lazyload();
proportionalScroll();

window.homeVue = initMain(
  [tfmixin, homeMixin, ripeTabsMixin],
  {
    HScroll,
    MyNews,
    DolarSpotGraph,
    MiniQuoteGraph,
    QuoteGraph,
    TextFill,
    TwentyTwenty,
    InsideHome,
    Live70Years,
    Trends,
    DayNews,
    LrPlusBenefits,
    HomeElections2023,
    VideoElections2023,
    VueTinySlider
  },
  [CarouselPlugin, TabsPlugin]
);
header([
  {
    created() {
      this.userNews();
    },
  },
]);
